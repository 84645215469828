
import { defineComponent, inject, PropType } from 'vue'
import { required } from '@vuelidate/validators'

import { useVuelidate } from '@vuelidate/core'
import { useToast } from '@/util/toast'
import { useErrorHandler } from '@/util/error-handler'

import { PondRepositoryKey } from '@/data/injectables'
import { CreatePondParams, PondForm } from '@/data/pond/types'
import { UserLogData } from '@/data/auth/types'

import FormField from '@/components/FormField.vue'
import NavBar from '@/components/navbar/NavBar.vue'
import BlockUI from '@/components/BlockUI.vue'
import UserLog from '@/components/UserLog.vue'

export default defineComponent({
  components: { NavBar, FormField, BlockUI, UserLog },
  props: {
    pondId: {
      type: Number as PropType<number>,
      default: null,
    },
  },
  setup: () => {
    const pondRepository = inject(PondRepositoryKey)
    const toast = useToast()
    const { handleError } = useErrorHandler(toast)
    return {
      v$: useVuelidate(),
      pondRepository,
      handleError,
      ...toast,
    }
  },
  data() {
    return {
      saving: false,
      deleting: false,
      loading: false,
      form: {
        name: '',
        inactive: false,
      } as PondForm,
      userLog: {} as UserLogData,
      submitted: false,
    }
  },
  computed: {
    editing(): boolean {
      return !!this.pondId
    },
    title(): string {
      return this.editing ? 'Editar açude' : 'Criar açude'
    },
    busy(): boolean {
      return this.loading || this.saving || this.deleting
    },
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
      },
    }
  },
  created() {
    if (this.editing) {
      this.loadFormAndUserLog()
    }
  },
  methods: {
    async handleSave(): Promise<void> {
      this.submitted = true
      if (this.v$.$invalid) return

      this.saving = true
      if (this.editing) {
        await this.updatePond()
      } else {
        await this.createPond()
      }
      this.saving = false
    },
    async handleDelete(): Promise<void> {
      this.$confirm.require({
        message: 'Confirma a deleção do açude?',
        header: 'Confirmação',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          await this.deletePond()
        },
      })
    },
    async updatePond(): Promise<void> {
      try {
        await this.pondRepository?.updatePond(this.pondId, this.form)
        this.showSuccess({
          title: 'Tudo certo',
          detail: 'Açude editado com sucesso.',
        })
        this.$router.back()
      } catch (e) {
        this.handleError(e)
      }
    },
    async createPond(): Promise<void> {
      try {
        await this.pondRepository?.createPond(this.form as CreatePondParams)
        this.showSuccess({
          title: 'Tudo certo',
          detail: 'Açude criado com sucesso.',
        })
        this.$router.back()
      } catch (e) {
        this.handleError(e)
      }
    },
    async deletePond(): Promise<void> {
      try {
        this.deleting = true
        await this.pondRepository?.deletePond(this.pondId)
        this.showSuccess({
          title: 'Tudo certo',
          detail: 'Açude deletado com sucesso.',
        })
        this.$router.back()
      } catch (e) {
        this.handleError(e)
      } finally {
        this.deleting = false
      }
    },
    async loadFormAndUserLog(): Promise<void> {
      try {
        this.loading = true
        const { createdBy, modifiedBy, ...form } =
          (await this.pondRepository?.getPond(this.pondId)) || {}

        this.form = form
        this.userLog = {
          createdBy,
          modifiedBy,
        }
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
  },
})
